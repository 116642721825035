import React from 'react';
import './styles.scss'
import classNames from "classnames";

type Props = {
    text: string;
    onClick: () => void;
    fullWidth?: boolean;
}

export const Button: React.FC<Props> = ({
    text,
    onClick,
    fullWidth = false
}) => {

    const onClickHandler = () => {
        onClick();
    }
  return (
    <div
        className={
        classNames('button', {
            'button--full-width': fullWidth
        })}
        onClick={onClickHandler}
    >{text}</div>
  );
};