import React, {FC} from 'react';
import './styles.scss';
import {Button} from "../../../../components/button";
import {AnimatedClip} from "../../../../components/AnimatedClip";
import {ReactComponent as TelegramIcon} from './assets/telegram.svg';
import {ReactComponent as WatsUpIcon} from './assets/watsup.svg';

type Props = {
    onClick: () => void;
}
export const Welcome: FC<Props> = ({onClick}) => {

    return <div className="welcome">
        <div className="welcome__animation">
            <AnimatedClip type="cool" />
        </div>
        <h1 className="welcome__title">
            This domain is for sale!
        </h1>
        <div className="welcome__control">
            <Button text="Our domains" onClick={onClick} />
        </div>
        <div className="welcome__contacts">
            <h2 className="welcome__contacts-title">
                Contact Us:
            </h2>
            <div className="welcome__contacts-list">
                <a href="https://t.me/rodnomir" target="__blank">
                    <TelegramIcon />
                    <span>Telegram</span>
                </a>
               {/* <a href="/" target="__blank">
                    <WatsUpIcon />
                    <span>
                        WhatsApp
                    </span>
                </a>*/}
            </div>
        </div>
    </div>
}