import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/font/Inter/inter-font.css';
import './index.scss';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
