import React, {FC} from 'react';
import {list} from "./domains";
import {Button} from "../../../../components/button";
import {ReactComponent as BackIcon} from './back-icon.svg';
import './styles.scss';

type Props = {
    onClick: () => void;
    onBack: () => void;
}
export const Domains:FC<Props> = ({onClick, onBack}) => {

    return <div className="domains">
        <h1 className="domains__title">
            <BackIcon className="backbtn" onClick={onBack} />Sale of trade marks
        </h1>
        <div className="domains__list">
           <div className="domains__list-heading">
                <div className="domains__list-heading-item first">
                     Domain
                </div>
                <div className="domains__list-heading-item second">
                     Price
                </div>
           </div>
            {list.map((item, index) => {
                return <div className="domains__list-item" key={index}>
                    <div className="domains__list-item-name first">
                        <a href={item.url} target="_blank">{item.title}</a>
                    </div>
                    <div className="domains__list-item-price second">
                        {item.price}
                    </div>
                </div>
            })}

            <Button
                onClick={onClick}
                text="Connect"
                fullWidth
            />
        </div>

    </div>

}