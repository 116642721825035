export const list = [
    {
        url: 'http://aromakava.com',
        title: 'aromakava.com',
        price: '990$'
    },
    {
        url: 'http://budynokkavy.com',
        title: 'budynokkavy.com',
        price: '990$'
    },
    {
        url: 'http://burgomister.com',
        title: 'burgomister.com',
        price: '990$'
    },
    {
        url: 'http://burgomisto.com',
        title: 'burgomisto.com',
        price: '990$'
    },
    {
        url: 'http://dimkavy.com',
        title: 'dimkavy.com',
        price: '990$'
    },
    {
        url: 'http://eservak.com',
        title: 'eservak.com',
        price: '990$'
    },
    {
        url: 'http://keramhall.com',
        title: 'keramhall.com',
        price: '990$'
    },
    {
        url: 'http://marketpiva.com',
        title: 'marketpiva.com',
        price: '990$'
    },
    {
        url: 'http://modomebel.com',
        title: 'modomebel.com',
        price: '990$'
    },
    {
        url: 'http://nailipshi.com',
        title: 'nailipshi.com',
        price: '990$'
    },
    {
        url: 'http://olmale.com',
        title: 'olmale.com',
        price: '990$'
    },
    {
        url: 'http://peyzdes.com',
        title: 'peyzdes.com',
        price: '990$'
    },
    {
        url: 'http://pyvko.com',
        title: 'pyvko.com',
        price: '990$'
    },
    {
        url: 'http://velykidyva.com',
        title: 'velykidyva.com',
        price: '990$'
    }
];
