import React from 'react';
import {HomePage} from "./pages/Home";

function App() {
  return (
    <div className="App">
        <HomePage />
    </div>
  );
}

export default App;
