import React from 'react';
import './styles.scss';
import {Button} from "../../components/button";
import {Welcome} from "./components/welcome";
import {Domains} from "./components/domains";

export const HomePage = () => {
    const [showDomains, setShowDomains] = React.useState(false);
    return <div className='page'>
        <div className="page__content">
            {!showDomains && <Welcome onClick={() => {
                setShowDomains(true);
            }} />}
            {showDomains && <Domains
                onClick={() => {
                    window.open('https://t.me/rodnomir', '_blank');
                }}
                onBack={() => {
                    setShowDomains(false);
                }}
            />}
        </div>

    </div>
}